import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import AcceptAgreement from "./views/AcceptAgreement";



export default function Layout() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="" exact={true}  component={AcceptAgreement} />} />
                <Route path="/agreement" exact={true}  component={AcceptAgreement} />} />
            </Switch>
        </BrowserRouter>
    );
}
