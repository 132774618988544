import {useEffect, useState} from "react";
import {loadAgreement} from "../api/agreementAPI";

export default function useFetchAgreement(code, setCustomer, setBankAccount, setIsAccepted) {

    const [agreement, setAgreement] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        async function fetch() {
            try {
                const response = await loadAgreement(code);
                setAgreement(response.data);
                setCustomer(response.data.customer);
                setBankAccount(response.data.bank_account);
                if (response.data.status === 'ACCEPTED') {
                    setIsAccepted(true);
                }
            } catch (e) {
                setError(e)
            } finally {
                setIsLoading(false);
            }
        }
        if (code) {
            fetch();
        } else {
            setIsLoading(false);
            setError("UnAuthorized")
        }

    }, []);

    return {
        agreement,
        isLoading,
        error
    }

}