import bank from "../icons/bankAcount.svg";
import Typography from "@material-ui/core/Typography";
import {FormGroup, OutlinedInput} from "@material-ui/core";

export default function BankDetails({bankAccount, setBankAccount}) {

    return (
        <div className="d-flex flex-column flex-sm-row m-4">
            <div className="d-flex flex-row flex-sm-column" style={{width: "30%"}}>
                <img src={bank} alt="merchant" style={{width: "30%"}}/>
                <Typography variant="h1" component="h1" className="ml-3 ml-sm-0">
                    Bank Account
                </Typography>
            </div>
            <div className="d-flex flex-column w-100">
                <div className="row">
                    <FormGroup className="col-md-5">
                        <Typography variant="h3" component="h3">
                            Account Name *
                        </Typography>
                        <OutlinedInput
                            fullWidth
                            type="text"
                            placeholder="Account Name"
                            value={bankAccount.name}
                            onChange={e => setBankAccount({...bankAccount, name: e.target.value})}
                        />
                    </FormGroup>
                </div>
                <div className="row">
                    <FormGroup className="col-md-2">
                        <Typography variant="h3" component="h3">
                            BSB *
                        </Typography>
                        <OutlinedInput
                            fullWidth
                            type="text"
                            placeholder="BSB"
                            value={bankAccount.bsb}
                            onChange={e => setBankAccount({...bankAccount, bsb: e.target.value})}
                        />
                    </FormGroup>
                    <FormGroup className="col-md-4">
                        <Typography variant="h3" component="h3">
                            Account Number *
                        </Typography>
                        <OutlinedInput
                            fullWidth
                            type="text"
                            placeholder="Account No"
                            value={bankAccount.no}
                            onChange={e => setBankAccount({...bankAccount, no: e.target.value})}
                        />
                    </FormGroup>
                </div>
            </div>
        </div>
    );
}