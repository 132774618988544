import Typography from "@material-ui/core/Typography";
import debit from "icons/debit.svg";

export default function DebitDetails({frequency, startDate, endDate, amount}) {
    function getUnitType() {
        let type = ''
        switch (frequency.time_unit) {
            case 'MONTH':
                type = 'Month'
                break;
            case 'DAY':
                type = 'Day'
                break;
            case 'WEEK':
                type = 'Week'
                break;
            case 'YEAR':
                type = 'Year'
                break;
            default:
                break;
        }
        if (frequency.no_of_units > 1 && type) {
            type += 's'
        }
        return type;
    }

    return (
        <div className="d-flex flex-column flex-sm-row m-4">
            <div className="d-flex flex-row flex-sm-column" style={{width: "30%"}}>
                <img src={debit} alt="merchant" style={{width: "30%"}}/>
                <Typography variant="h1" component="h1" className="ml-3 ml-sm-0">
                    Recurring Debit
                </Typography>
            </div>
            <div className="d-flex flex-column w-100">
                <div className="d-flex">
                    <div className="d-flex justify-content-between mr-5" style={{width: "20%"}}>
                        <Typography variant="h3" component="h3">
                            Amount
                        </Typography>
                        <Typography variant="h3" component="h3">
                            :
                        </Typography>
                    </div>
                    <div className="d-flex">
                        <Typography variant="h3" component="h3">
                            {`$${amount}`}
                        </Typography>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="d-flex justify-content-between mr-5" style={{width: "20%"}}>
                        <Typography variant="h3" component="h3">
                            Frequency
                        </Typography>
                        <Typography variant="h3" component="h3">
                            :
                        </Typography>
                    </div>
                    <div className="d-flex">
                        <Typography variant="h3" component="h3">
                            {`Every ${frequency.no_of_units} ${getUnitType()}`}
                        </Typography>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="d-flex justify-content-between mr-5" style={{width: "20%"}}>
                        <Typography variant="h3" component="h3">
                            Start Date
                        </Typography>
                        <Typography variant="h3" component="h3">
                            :
                        </Typography>
                    </div>
                    <div className="d-flex">
                        <Typography variant="h3" component="h3">
                            {startDate}
                        </Typography>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="d-flex justify-content-between mr-5" style={{width: "20%"}}>
                        <Typography variant="h3" component="h3">
                            End Date
                        </Typography>
                        <Typography variant="h3" component="h3">
                            :
                        </Typography>
                    </div>
                    <div className="d-flex w-50">
                        <Typography variant="h3" component="h3">
                            {endDate}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
}