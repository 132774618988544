import axios from "axios";

export function loadAgreement(jwt) {
    const headers = {
        Authorization: `Bearer ${jwt}`
    }
    return axios.get(`${process.env.REACT_APP_AGREEMENT_HOST_URL}/v1/details`, {headers});
}

export function acceptAgreement(jwt, request) {
    const headers = {
        Authorization: `Bearer ${jwt}`
    }
    return axios.patch(`${process.env.REACT_APP_AGREEMENT_HOST_URL}/v1/accept`, request, {headers});
}