import {makeStyles} from "@material-ui/core/styles";
import componentStyles from "../argon/assets/theme/views/admin/buttons";
import componentStylesButtons from "../argon/assets/theme/components/button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {Switch} from "@material-ui/core";
import brandIcon from "images/Novatti PNG.png"
import MerchantDetails from "./MerchantDetails";
import DebitDetails from "./DebitDetails";
import CustomerDetails from "./CustomerDetails";
import BankDetails from "./BankDetails";
import useFetchAgreement from "../hook/useFetchAgreement";
import Loader from "./common/Loader";
import {useState} from "react";
import useAcceptAgreement from "../hook/useAcceptAgreement";
import ErrorPage from "./common/ErrorPage";
import UnAuthorized from "./common/UnAuthorized";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);

export default function AcceptAgreement(props) {
    const classes = { ...useStyles(), ...useStylesButtons() };

    const code = new URLSearchParams(props.location.search).get("code");

    const [customer, setCustomer] = useState({});
    const [bankAccount, setBankAccount] = useState({});
    const [termsAccepted, setTermsAccepted] = useState(false);

    const {isSaving, isAccepted, setIsAccepted, submitRequest} = useAcceptAgreement();
    const {isLoading, agreement, error} = useFetchAgreement(code, setCustomer, setBankAccount, setIsAccepted);

    function accept() {
        const request = {
            customer,
            bank_account: bankAccount
        };
        submitRequest(code, request);
    }

    if (!code) return <UnAuthorized/>
    if (isLoading) return <Loader/>
    if (error) return <ErrorPage/>

    return (
        <Container
            maxWidth={false}
            component={Box}
            classes={{ root: classes.containerRoot }}
            // style={{width: "80%"}}
        >
            <div className="d-flex flex-column">

                <div className="d-flex justify-content-center m-2">
                    <img src={brandIcon} alt="Novatti" className="w-25"/>
                </div>

                <div className="d-flex justify-content-center m-2">
                    <Typography variant="h1" component="h1">
                        {agreement.title}
                    </Typography>
                </div>
                <hr style={{border: '1px solid #ced4da', width: "100%", margin: "0"}}/>

                <MerchantDetails merchant={agreement.merchant} />
                <hr style={{border: '1px solid #ced4da', width: "100%", margin: "0"}}/>

                <DebitDetails frequency={agreement.frequency} endDate={agreement.end_date}
                    startDate={agreement.start_date} amount={agreement.amount} />
                <hr style={{border: '1px solid #ced4da', width: "100%", margin: "0"}}/>

                <CustomerDetails customer={customer} setCustomer={setCustomer}/>
                <hr style={{border: '1px solid #ced4da', width: "100%", margin: "0"}}/>

                <BankDetails bankAccount={bankAccount} setBankAccount={setBankAccount} />
                <hr style={{border: '1px solid #ced4da', width: "100%", margin: "0"}}/>

                <div className="d-flex mt-4">
                    <Switch name="checkedA" value={termsAccepted} onChange={e => setTermsAccepted(e.target.checked)} className="mr-3"/>
                    <Typography variant="h3" component="h3">
                        I/We have read and accept the <a href={agreement.terms_link}  target="_blank" style={{color: "blue"}}><u>Service Agreement</u></a>, and authorize Novatti Payments to debit
                        my/our account at the financial identified here through the Bulk Electronic Clearing System (BECS)
                    </Typography>
                </div>

                <div className="d-flex justify-content-center my-3">
                    {
                        isAccepted ? (
                            <Typography variant="h3" component="h3" className="p-2 rounded" style={{border: "1px solid #0b456e"}}>
                                You have accepted the agreement.
                            </Typography>
                        ) : (
                            <Button variant="contained" onClick={accept} disabled={isSaving || !termsAccepted} style={{width: "12rem"}}>
                                {isSaving ? 'Saving...' : 'Submit'}
                            </Button>
                        )
                    }
                </div>
            </div>
        </Container>
    );
}