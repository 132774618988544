import {createContext, useState} from "react";

const snackbarContext = createContext();

function SnackbarContextProvider({children}) {

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarType, setSnackbarType] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(false);

    function setSnackbar(isOpen, type, message) {
        setSnackbarOpen(isOpen);
        setSnackbarMessage(message);
        setSnackbarType(type);
    }

    return (
        <snackbarContext.Provider
            value={{
                snackbarOpen,
                snackbarType,
                snackbarMessage,
                setSnackbar
            }}
        >
            {children}
        </snackbarContext.Provider>
    );
}

export {snackbarContext, SnackbarContextProvider}