import React, {useContext} from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import {snackbarContext} from "./context/snackbarContext";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2)
        }
    }
}));

export default function CustomizedSnackbar() {
    const classes = useStyles();
    const {snackbarOpen, snackbarType, snackbarMessage, setSnackbar} = useContext(snackbarContext);
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbar(false, snackbarType, snackbarMessage);
    };

    return (
        <div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{vertical: 'top' , horizontal: "right"}}
                style={{maxWidth: "30rem"}}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}
                    color={snackbarType}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}
