import axios from "axios";

export default function configureResponseInterceptor() {
    axios.interceptors.response.use(response => {
        if (response.data) {
            return response.data
        }
        return response;
    }, error => {
        return Promise.reject(error);
    });
}