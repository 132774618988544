import Typography from "@material-ui/core/Typography";
import merchantIcon from "../icons/merchant.svg";

export default function MerchantDetails({merchant}) {
    return (
        <div className="d-flex flex-column flex-sm-row m-4">
            <div className="d-flex flex-row flex-sm-column" style={{width: "30%"}}>
                <img src={merchantIcon} alt="merchant" style={{width: "30%"}}/>
                <Typography variant="h1" component="h1" className="ml-3 ml-sm-0">
                    Merchant
                </Typography>
            </div>
            <div className="d-flex flex-column w-100">
                <div className="d-flex">
                    <div className="d-flex justify-content-between mr-5" style={{width: "20%"}}>
                        <Typography variant="h3" component="h3">
                            Name
                        </Typography>
                        <Typography variant="h3" component="h3">
                            :
                        </Typography>
                    </div>
                    <div className="d-flex">
                        <Typography variant="h3" component="h3">
                            {merchant.trading_name}
                        </Typography>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="d-flex justify-content-between mr-5" style={{width: "20%"}}>
                        <Typography variant="h3" component="h3">
                            Address
                        </Typography>
                        <Typography variant="h3" component="h3">
                            :
                        </Typography>
                    </div>
                    <div className="d-flex">
                        <Typography variant="h3" component="h3">
                            {merchant.address}
                        </Typography>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="d-flex justify-content-between mr-5" style={{width: "20%"}}>
                        <Typography variant="h3" component="h3">
                            Phone
                        </Typography>
                        <Typography variant="h3" component="h3">
                            :
                        </Typography>
                    </div>
                    <div className="d-flex">
                        <Typography variant="h3" component="h3">
                            {merchant.contact_number}
                        </Typography>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="d-flex justify-content-between mr-5" style={{width: "20%"}}>
                        <Typography variant="h3" component="h3">
                            Website
                        </Typography>
                        <Typography variant="h3" component="h3">
                            :
                        </Typography>
                    </div>
                    <div className="d-flex w-50">
                        <Typography variant="h3" component="h3">
                            {merchant.website}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
}