import {useContext, useState} from "react";
import {acceptAgreement} from "../api/agreementAPI";
import {snackbarContext} from "../context/snackbarContext";

export default function useAcceptAgreement() {
    const [isSaving, setIsSaving] = useState(false);
    const [isAccepted, setIsAccepted] = useState(false);

    const {setSnackbar} = useContext(snackbarContext);

    function validateRequest(request) {
        const customer = request.customer;
        const bank = request.bank_account;
        if (!customer.first_name || !customer.last_name || !customer.address || !customer.city || !customer.state || !customer.post_code) {
            return false;
        }
        if (!bank.bsb || !bank.name || !bank.no) {
            return false;
        }
        return true;
    }

    async function submitRequest(code, request) {
        if (validateRequest(request)) {
            setIsSaving(true);
            try {
                await acceptAgreement(code, request);
                setSnackbar(true, "success", "Accepted successfully");
                setIsAccepted(true);
            } catch (e) {
                setSnackbar(true, "error", "Error Occurred!");
            } finally {
                setIsSaving(false);
            }
        } else {
            setSnackbar(true, "error", "Please provide required fields");
        }
    }

    return {
        submitRequest,
        isSaving,
        isAccepted,
        setIsAccepted
    }
}