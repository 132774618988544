import customerIcon from "../icons/customer.svg";
import Typography from "@material-ui/core/Typography";
import {FormGroup, OutlinedInput} from "@material-ui/core";

export default function CustomerDetails({customer, setCustomer}) {

    return (
        <div className="d-flex flex-column flex-sm-row m-4">
            <div className="d-flex flex-row flex-sm-column" style={{width: "30%"}}>
                <img src={customerIcon} alt="merchant" style={{width: "30%"}} />
                <Typography variant="h1" component="h1" className="ml-3 ml-sm-0">
                    Customer
                </Typography>
            </div>
            <div className="d-flex flex-column w-100">
                <div className="d-flex">
                    <div className="d-flex justify-content-between mr-5" style={{width: "20%"}}>
                        <Typography variant="h3" component="h3">
                            Email
                        </Typography>
                        <Typography variant="h3" component="h3">
                            :
                        </Typography>
                    </div>
                    <div className="d-flex">
                        <Typography variant="h3" component="h3">
                            {customer.email}
                        </Typography>
                    </div>
                </div>
                <div className="mt-4">
                    <div className="row">
                        <FormGroup className="col-md-4">
                            <Typography variant="h3" component="h3">
                                First Name *
                            </Typography>
                            <OutlinedInput
                                fullWidth
                                type="text"
                                placeholder="First Name"
                                value={customer.first_name}
                                onChange={e => setCustomer({...customer, first_name: e.target.value})}
                            />
                        </FormGroup>
                        <FormGroup className="col-md-4">
                            <Typography variant="h3" component="h3">
                                Last Name *
                            </Typography>
                            <OutlinedInput
                                fullWidth
                                type="text"
                                placeholder="Last Name"
                                value={customer.last_name}
                                onChange={e => setCustomer({...customer, last_name: e.target.value})}
                            />
                        </FormGroup>
                    </div>

                    {/*Customer Address*/}
                    {/*<FormLabel>Customer Address</FormLabel>*/}
                    <div className="row">
                        <FormGroup className="col-md-6">
                            <Typography variant="h3" component="h3">
                                Address *
                            </Typography>
                            <OutlinedInput
                                fullWidth
                                type="text"
                                placeholder="Address"
                                value={customer.address}
                                onChange={e => setCustomer({...customer, address: e.target.value})}
                            />
                        </FormGroup>
                    </div>
                    <div className="row">
                        <FormGroup className="col-md-4">
                            <Typography variant="h3" component="h3">
                                City *
                            </Typography>
                            <OutlinedInput
                                fullWidth
                                type="text"
                                placeholder="City"
                                value={customer.city}
                                onChange={e => setCustomer({...customer, city: e.target.value})}
                            />
                        </FormGroup>
                        <FormGroup className="col-md-3">
                            <Typography variant="h3" component="h3">
                                State *
                            </Typography>
                            <OutlinedInput
                                fullWidth
                                type="text"
                                placeholder="State"
                                value={customer.state}
                                onChange={e => setCustomer({...customer, state: e.target.value})}
                            />
                        </FormGroup>
                        <FormGroup className="col-md-2">
                            <Typography variant="h3" component="h3">
                                Post Code *
                            </Typography>
                            <OutlinedInput
                                fullWidth
                                type="text"
                                placeholder="Post Code"
                                value={customer.post_code}
                                onChange={e => setCustomer({...customer, post_code: e.target.value})}
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
        </div>
    );
}